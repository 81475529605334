import {
  Box,
  Container,
  Typography,
  Stack,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React from "react";
import Sheild from "../assets/mlsc.png";
import Bg from "./bg";

const CloseRegistration = () => {
  const matches = useMediaQuery("(max-width:570px)");
  const glassmorphismTheme = createTheme({
    palette: {
      // Customize your theme colors here
      primary: {
        main: "#3366FF",
      },
      secondary: {
        main: "#FF5722",
      },
    },
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
    textfeild: {
      color: "#FFFFFF",
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
  });
  return (
    <>
    <Bg />
    <ThemeProvider theme={glassmorphismTheme}>
    <Box
      sx={{
        height: "100vh",
        // paddingTop: "4rem",
        backgroundColor: "#18181b",
        color: "white",

      }}
    >
      <Container
        sx={{
          display: "flex",
          textAlign: "center",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Stack
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
          style={{zIndex: 50}}
        >
          <img src={Sheild} alt="sheild" width={matches ? "100px" : "150px"} />
          <Typography item variant="h4" component="h1" gutterBottom>
            Thank you for your Responses
          </Typography>
          <Typography item variant="h3" component="h1" gutterBottom>
            Forms are closed !!
          </Typography>
          <Typography item variant="h5" component="h1" gutterBottom>
            We'll be back soon.
          </Typography>
        </Stack>
      </Container>
    </Box>
    </ThemeProvider>
    </>
  );
};

export default CloseRegistration;
