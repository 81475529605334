// import Form from "./components/form";
import CloseRegistration from "./components/CloseRegistration";
function App() {
  return (
    <div className="App">
      {/* <Form /> */}
      <CloseRegistration />
    </div>
  );
}

export default App;
